import { css } from '@emotion/css';
import styled from '@emotion/styled';
import { PopularProject } from '@src/models/event';
import HeartIcon from '@src/assets/heart-icon.svg';
import HeartFillIcon from '@src/assets/heart-fill-icon.svg';
import PlayIcon from '@src/assets/play-icon.svg';
import { usePostMessage } from '@src/hooks/usePostMessage';
import { usePostProjectLike } from '@src/apis/mutations';
import { trackEvent } from '@src/utils/analytics';
import { createDeeplinkUrl } from '@src/utils/deeplink';
import { useState } from 'react';

interface EventPopularItemProps {
  popularProject: PopularProject;
  preferenceName?: string;
  section?: 'popular' | 'local' | 'recommendation';
}

export const EventPopularItem = ({ popularProject, preferenceName, section }: EventPopularItemProps) => {
  const postMessageToParent = usePostMessage();
  const { mutateAsync: likeProject, isPending } = usePostProjectLike(popularProject.id);
  const [isLiked, setIsLiked] = useState(popularProject.isLiked);
  const clickProjectMessage: PostMessageType<ActionMessage> = {
    type: 'action',
    data: {
      messageDataType: 'click_project',
      payload: { status: true, projectId: popularProject.id },
    },
  };

  const handleProjectItem = () => {
    trackEvent({
      eventName: 'click_event_page_project',
      properties: {
        section: section,
        projectName: popularProject.name,
        projectId: popularProject.id,
        playType: preferenceName,
      },
    });

    if (window.isFlutter()) {
      postMessageToParent<ActionMessage>(clickProjectMessage);
    } else {
      window.location.href = createDeeplinkUrl(popularProject);
    }
  };

  const handleLikeProject = async (event: React.MouseEvent) => {
    event.stopPropagation();
    if (isPending) return;

    setIsLiked(!isLiked);
    const success = await likeProject();

    if (success.success) {
      trackEvent({
        eventName: 'click_event_page_project_like',
        properties: {
          projectName: popularProject.name,
          projectId: popularProject.id,
          playType: preferenceName,
        },
      });
    }
  };

  return (
    <EventPopularItemStyle
      imageUrl={popularProject.verticalImageUrl || popularProject.horizontalImageUrl || ''}
      onClick={handleProjectItem}
    >
      <img src={popularProject.verticalImageUrl || popularProject.horizontalImageUrl || ''} />
      <div className={EventPopularItemContentStyle}>
        <p className={EventPopularItemNameStyle}>{popularProject.name}</p>
        <p className={EventPopularItemDescriptionStyle}>{popularProject.description}</p>
        <div className={EventPopularItemButtonSectionStyle}>
          <div className={EventPopularItemPlayButton} onClick={handleProjectItem}>
            <img style={{ width: '24px', height: '24px' }} src={PlayIcon} />
            <p>게임 시작</p>
          </div>
          {isLiked && <img style={{ width: '48px', height: '48px' }} src={HeartFillIcon} onClick={handleLikeProject} />}
          {!isLiked && <img style={{ width: '48px', height: '48px' }} src={HeartIcon} onClick={handleLikeProject} />}
        </div>
      </div>
    </EventPopularItemStyle>
  );
};

const EventPopularItemStyle = styled.div<{ imageUrl: string }>`
  position: relative;
  width: 100%;
  height: 100%;
  flex-shrink: 0;
  border-radius: 16px;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    background: ${($props) => `url(${$props.imageUrl}) lightgray 50% / cover no-repeat;`};
    left: 0%;
    bottom: -5%;
    transform: translateY(-5%);
    width: 100%;
    height: 55%;
    background-position: bottom;
    background-size: cover;
    filter: blur(20px);
  }
`;

const EventPopularItemContentStyle = css`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;

  padding: 0px 36px;
  gap: 8px;

  padding-bottom: 34px;

  > p {
    width: 100%;
  }
`;

const EventPopularItemNameStyle = css`
  overflow: hidden;

  color: var(--text-text-on-color, #fff);
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;

  /* Headline */
  font-size: var(--font-size-font-size-1, 20px);
  font-style: normal;
  font-weight: var(--font-weight-font-weight-extrabold, 800);
  line-height: var(--line-height-font-height-1, 28px); /* 140% */
`;

const EventPopularItemDescriptionStyle = css`
  color: var(--text-text-on-color-description, #f5f5f5);
  text-align: center;

  /* Body2 */
  font-family: var(--font-family-Pretendard, Pretendard);
  font-size: var(--font-size-font-size-4, 14px);
  font-style: normal;
  font-weight: var(--font-weight-font-weight-regular, 400);
  line-height: var(--line-height-font-height-4, 20px); /* 142.857% */
  letter-spacing: -0.1px;

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
`;

const EventPopularItemButtonSectionStyle = css`
  display: flex;
  flex-direction: row;
  padding-top: 8px;
  justify-content: center;
  align-items: center;
  gap: 16px;
  align-self: stretch;
`;

const EventPopularItemPlayButton = css`
  display: flex;
  height: 52px;
  padding: 0px 20px 0px 16px;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;

  border-radius: var(--btn-rounded, 999px);
  background: var(--surface-surface-on-color, #fff);

  color: var(--text-text-default, #1a1a1a);
  text-align: end;

  /* Subhead3 */
  font-family: var(--font-family-Pretendard, Pretendard);
  font-size: var(--font-size-font-size-4, 14px);
  font-style: normal;
  font-weight: var(--font-weight-font-weight-bold, 700);
  line-height: var(--line-height-font-height-4, 20px); /* 142.857% */
`;
