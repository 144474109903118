import { useQuery } from '@tanstack/react-query';
import { getEventPage, getStories, getStory, getUserInfo } from '@src/apis/index';

export const useGetUserInfo = () => {
  return useQuery({ queryKey: ['getUserInfo'], queryFn: getUserInfo });
};

export const useGetStories = (queryParam?: Record<string, string>) => {
  return useQuery({ queryKey: ['getStories', queryParam], queryFn: getStories });
};

export const useGetStory = (id?: string) => {
  return useQuery({ queryKey: ['getStories', id], queryFn: () => getStory(id!), enabled: !!id });
};

export const useGetEventPage = () => {
  return useQuery({ queryKey: ['getEventPage'], queryFn: getEventPage });
};
