import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { Global } from '@emotion/react';
import { globalStyle } from '@src/global.style.ts';
import { HomePage } from '@src/pages/Home';
import { NotFound } from '@src/pages/NotFound';
import { EventPage } from '@src/pages/Event';
import { StoryPage } from '@src/pages/Story';
import { StoriesPage } from '@src/pages/Stories';
import { useEffect } from 'react';
import { identifyUser, initTracker } from '@src/utils/analytics';
import { initializeFlutterInterface } from './utils/flutter-interface';
import { useGetUserInfo } from './apis/queries';
import { getUserInfoFromLocalStorage } from './utils/util';

const router = createBrowserRouter([
  { path: '/', element: <HomePage /> },
  { path: '/stories', element: <StoriesPage /> },
  { path: '/stories/:id', element: <StoryPage /> },
  { path: '/events/:id', element: <EventPage /> },
  { path: '*', element: <NotFound /> },
]);

initializeFlutterInterface();

export const App = () => {
  const { refetch } = useGetUserInfo();

  const setUserInfo = async () => {
    const accessToken = localStorage.getItem('accessToken');
    const refreshToken = localStorage.getItem('refreshToken');

    if (accessToken && refreshToken) {
      const result = await refetch();

      if (result.isSuccess && result.data) {
        localStorage.setItem('userInfo', JSON.stringify(result.data));

        const userInfo = getUserInfoFromLocalStorage();
        if (userInfo) identifyUser(userInfo.id);
      }
    }
  };

  useEffect(() => {
    initTracker();
    setUserInfo();
  }, []);

  return (
    <>
      <Global styles={globalStyle} />
      <RouterProvider router={router} />
    </>
  );
};
