import { css } from '@emotion/css';
import styled from '@emotion/styled';
import { Img } from 'react-image';
import BackArrowBlackIcon from '@src/assets/back-arrow-black.svg';
import BackArrowWhiteIcon from '@src/assets/back-arrow-white.svg';
import CloseIcon from '@src/assets/close.svg';

interface NavigationProps {
  title?: string;
  isClose?: boolean;
  hexColor?: string;
  arrowColor?: 'black' | 'white';
  onClickClose?: () => void;
  onClickBack?: () => void;
}

export const TopNavigation = ({
  title,
  isClose,
  hexColor,
  arrowColor = 'black',
  onClickBack,
  onClickClose,
}: NavigationProps) => {
  return (
    <TopNavigationStyle hexColor={hexColor}>
      <div className={TopNavigationItemStyle}>
        {isClose && <Img src={CloseIcon} alt='close' style={{ padding: '8px' }} onClick={onClickClose} />}
        {!isClose && (
          <Img
            src={arrowColor === 'white' ? BackArrowWhiteIcon : BackArrowBlackIcon}
            alt='back_arrow'
            onClick={onClickBack}
          />
        )}
      </div>
      <div className={TopNavigationItemStyle}>
        <div className={TopNavigationTitleStyle}>{title}</div>
      </div>
      <div className={TopNavigationItemStyle}>
        <Img src={CloseIcon} alt='close' style={{ padding: '8px', visibility: 'hidden' }} />
      </div>
    </TopNavigationStyle>
  );
};

const TopNavigationStyle = styled.div<{ hexColor?: string }>`
  z-index: 999;
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 460px;
  height: 48px;
  padding: 0px 8px;
  background-color: ${(props) => props.hexColor ?? 'white'};
`;

const TopNavigationTitleStyle = css`
  display: inline;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  text-align: center;
  color: black;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const TopNavigationItemStyle = css`
  max-width: 300px;
  overflow: hidden;
`;
