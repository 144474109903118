import { css } from '@emotion/css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, EffectCoverflow } from 'swiper/modules';
import 'swiper/css';
import { PopularProject } from '@src/models/event';
import { EventPopularItem } from './EventPopularItem';

interface EventPopularProps {
  userName?: string;
  preferenceSimilarity?: number;
  preferenceName?: string;
  popularProjects: PopularProject[];
}

export const EventPopular = ({
  userName,
  preferenceName,
  preferenceSimilarity = 0,
  popularProjects,
}: EventPopularProps) => {
  const coverflowEffectConfig = {
    slideShadows: false,
    rotate: 0,
    stretch: 0,
    depth: 100,
  };

  return (
    <div className={EventPopularStyle}>
      <div className={EventPopularContentStyle}>
        <p className={UserNameStyle}>{userName}님을 위한</p>
        <p className={PopularTitleStyle}>7일간의 특별한 게임 추천!</p>
        <div className={PopularDescSectionStyle}>
          <p className={PopularDescStyle}>내 플레이 유형과</p>
          <p className={PopularSimilarityStyle}>{preferenceSimilarity}%</p>
          <p className={PopularDescStyle}>일치해요!</p>
        </div>
      </div>
      <div className={EventPopularProjectStyle}>
        <div className={EventPopularCardListStyle}>
          <Swiper
            modules={[EffectCoverflow, Autoplay]}
            effect='coverflow'
            slidesPerView='auto'
            autoplay={{ delay: 3000, disableOnInteraction: false }}
            speed={800}
            loop
            centeredSlides
            coverflowEffect={coverflowEffectConfig}
          >
            {popularProjects.map((project, idx) => (
              <SwiperSlide key={idx}>
                <EventPopularItem popularProject={project} preferenceName={preferenceName} section='popular' />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </div>
  );
};

const EventPopularStyle = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const EventPopularContentStyle = css`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  gap: 6px;
`;

const UserNameStyle = css`
  color: var(--text-text-on-color, #fff);
  text-align: center;
  font-size: var(--font-size-font-size-5, 14px);
  font-style: normal;
  font-weight: var(--font-weight-font-weight-regular, 400);
  line-height: var(--line-height-font-height-5, 20px); /* 142.857% */
`;

const PopularTitleStyle = css`
  color: var(--text-text-on-color, #fff);
  text-align: center;
  /* Hero02 */
  font-size: var(--font-size-font-size-1, 24px);
  font-style: normal;
  font-weight: var(--font-weight-font-weight-extrabold, 800);
  line-height: var(--line-height-font-height-1, 36px);
`;

const PopularDescSectionStyle = css`
  display: flex;
  flex-direction: row;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;

  border-radius: 999px;
  background: var(--transparent-black-20, rgba(0, 0, 0, 0.2));
`;

const PopularDescStyle = css`
  color: var(--text-text-on-color, #fff);
  text-align: center;
  font-size: var(--font-size-font-size-5, 14px);
  font-style: normal;
  font-weight: var(--font-weight-font-weight-regular, 400);
  line-height: var(--line-height-font-height-5, 20px); /* 142.857% */
  letter-spacing: -0.1px;
`;

const PopularSimilarityStyle = css`
  color: var(--illust-dagoo-body, #aaf0ff);
  text-align: center;

  font-family: var(--font-family-Pretendard, Pretendard);
  font-size: var(--font-size-font-size-3, 18px);
  font-style: normal;
  font-weight: var(--font-weight-font-weight-extrabold, 800);
  line-height: var(--line-height-font-height-3, 26px); /* 144.444% */
`;

const EventPopularProjectStyle = css`
  width: 100%;
  height: 100%;
  position: relative;
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    background: linear-gradient(
      to top,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 0.95) 60%,
      rgba(255, 255, 255, 0.1) 95%,
      transparent
    );
  }
`;

const EventPopularCardListStyle = css`
  padding-top: 32px;
  position: relative;

  .swiper-slide {
    width: 326px;
    height: 436px;
    margin-left: 10px;
    margin-right: 10px;
  }

  .swiper-slide img {
    width: 100%;
    height: 100%;
  }
`;
