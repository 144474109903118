import { css } from '@emotion/css';
import { ProjectLite } from '@src/models/project';
import { Badge } from '@src/components/Badge';
import { Img } from 'react-image';
import RatingIcon from '@src/assets/rating-icon.svg';
import { formatNumber } from '@src/utils/util';
import { LoadingSpinner } from './LoadingSpinner';
import { createDeeplinkUrl } from '@src/utils/deeplink';
import { Story } from '@src/models/story';
import { trackEvent } from '@src/utils/analytics';
import { usePostMessage } from '@src/hooks/usePostMessage';
import { Price } from './Price';

interface ProjectItemProps {
  story?: Story;
  project: ProjectLite;
  isShowPrice?: boolean;
  isEvent?: boolean;
  preferenceName?: string;
  section?: 'popular' | 'local' | 'recommendation';
}

export const ProjectItem = (props: ProjectItemProps) => {
  const postMessageToParent = usePostMessage();
  const clickProjectMessage: PostMessageType<ActionMessage> = {
    type: 'action',
    data: {
      messageDataType: 'click_project',
      payload: { status: true, projectId: props.project.id },
    },
  };

  const handleProjectItem = () => {
    if (props.isEvent) {
      trackEvent({
        eventName: 'click_event_page_project',
        properties: {
          section: props.section,
          projectName: props.project.name,
          projectId: props.project.id,
          preferenceName: props.preferenceName,
        },
      });
    } else {
      trackEvent({
        eventName: 'click_story_project',
        properties: {
          storyId: props.story?.id,
          storyTitle: props.story?.title,
          projectName: props.project.name,
          projectId: props.project.id,
        },
      });
    }

    if (window.isFlutter()) {
      postMessageToParent<ActionMessage>(clickProjectMessage);
    } else {
      if (props.isEvent) {
        window.location.href = createDeeplinkUrl(props.project);
      } else {
        window.location.href = createDeeplinkUrl(props.project, props.story!);
      }
    }
  };

  return (
    <div className={ProjectItemStyle} onClick={handleProjectItem}>
      <div className={ProjectItemImageStyle}>
        <Img
          loader={<LoadingSpinner />}
          src={props.project.verticalImageUrl ? props.project.verticalImageUrl : props.project.horizontalImageUrl!}
          alt={props.project.name}
        />
      </div>
      <div className={ProjectItemContentStyle}>
        <div className={ProjectItemTitleStyle}>{props.project.name}</div>
        <div className={ProjectItemDescriptionStyle}>{props.project.description}</div>
        {props.isShowPrice && <Price project={props.project} />}
        <div className={ProjectRatingStyle}>
          <Img src={RatingIcon} alt='rating' />
          {props.isEvent
            ? props.project.averageReviewRating?.toFixed(1) || '0.0'
            : props.project.rating?.toFixed(1) || '0.0'}{' '}
          {props.project.reviewCount ? `(${formatNumber(props.project.reviewCount)})` : '(0)'}
        </div>
        <Badge project={props.project} />
      </div>
    </div>
  );
};

const ProjectItemStyle = css`
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 16px;
  align-items: center;
`;

const ProjectItemImageStyle = css`
  flex-shrink: 0;
  width: 120px;
  height: 160px;
  border-radius: var(--Modal-all-8, 8px);
  background: var(--light-gray-300, #d6d6d8);

  > img {
    object-fit: fill;
    width: 100%;
    height: 100%;
    border-radius: var(--Modal-all-8, 8px);
    border: 1px solid var(--light-gray-200, #e8e9eb);
    background: var(--light-gray-300, #d6d6d8);
  }
`;

const ProjectItemContentStyle = css`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  overflow: hidden;
`;

const ProjectItemTitleStyle = css`
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px; /* 137.5% */
`;

const ProjectItemDescriptionStyle = css`
  width: 100%;
  height: 34px;
  color: var(--light-gray-600, var(--body, #8f9092));
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 133.333% */
`;

const ProjectRatingStyle = css`
  display: flex;
  align-items: center;
  gap: 2px;
  align-self: stretch;

  color: var(--text-text-description, #555);

  /* Caption1 */
  font-family: var(--font-family-Pretendard, Pretendard);
  font-size: var(--font-size-font-size-6, 12px);
  font-style: normal;
  font-weight: var(--font-weight-font-weight-medium, 500);
  line-height: var(--line-height-font-height-6, 16px); /* 133.333% */
`;
