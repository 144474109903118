import { css } from '@emotion/css';
import { useGetStory } from '@src/apis/queries';
import { LoadingSpinner } from '@src/components/LoadingSpinner';
import { ProjectItem } from '@src/components/ProjectItem';
import { TopNavigation } from '@src/components/TopNavigationBar';
import { Story } from '@src/models/story';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Img } from 'react-image';
import { usePostMessage } from '@src/hooks/usePostMessage';

export const StoryPage = () => {
  const { id } = useParams();
  const { data: storyData, isLoading } = useGetStory(id);
  const [story, setStory] = useState<Story>({} as Story);

  useEffect(() => {
    if (storyData?.data) {
      setStory(storyData.data as Story);
    }
  }, [storyData]);

  const renderProjects = () => {
    if (story.projects) {
      return story.projects.map((proj, index) => <ProjectItem key={index} story={story} project={proj} />);
    }
    return null;
  };

  const postMessageToParent = usePostMessage();
  const sendMessageToParent = () => {
    const closeMessage: PostMessageType<ActionMessage> = {
      type: 'action',
      data: {
        messageDataType: 'close',
        payload: { status: true },
      },
    };

    if (window.isFlutter()) postMessageToParent<ActionMessage>(closeMessage);
    else history.back();
  };

  return (
    <>
      <TopNavigation title={story.title} isClose={true} onClickClose={sendMessageToParent} />
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div className={StoryPageStyle}>
          {story.type === 'ImageHeader' && (
            <div className={StoryContentStyle}>
              <Img loader={<LoadingSpinner />} src={story.content!} alt={story.id} />
            </div>
          )}
          <div className={StoryProjectsStyle}>{renderProjects()}</div>
        </div>
      )}
    </>
  );
};

const StoryPageStyle = css`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding-top: 48px;
  padding-bottom: 65px;
  gap: 12px;
`;

const StoryContentStyle = css`
  align-items: center;
  > img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
`;

const StoryProjectsStyle = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  gap: 8px;
  padding: 0 16px;
`;
