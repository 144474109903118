import { css } from '@emotion/css';
import { useGetStories } from '@src/apis/queries';
import { LoadingSpinner } from '@src/components/LoadingSpinner';
import { StoryItem } from '@src/components/StoryItem';
import { TopNavigation } from '@src/components/TopNavigationBar';
import { Story } from '@src/models/story';
import { useEffect, useState } from 'react';

export const StoriesPage = () => {
  const { data: storiesData, isLoading } = useGetStories();
  const [stories, setStories] = useState<Story[]>([]);

  useEffect(() => {
    if (storiesData?.data && stories.length === 0) {
      setStories(storiesData?.data as Story[]);
    }
  }, [stories, storiesData]);

  const renderStories = () => {
    if (stories.length > 0) {
      return stories.map((story, index) => <StoryItem key={index} story={story} />);
    }
    return null;
  };

  return (
    <>
      <TopNavigation title='스토리 목록' isClose={true} />
      <div className={StoriesPageStyle}>{isLoading ? <LoadingSpinner /> : renderStories()}</div>
    </>
  );
};

const StoriesPageStyle = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 10px;
  padding: 0px 16px;
  padding-top: 48px;
`;
