import { css } from '@emotion/react';

export const globalStyle = css`
  @import url('https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.5/dist/web/static/pretendard-dynamic-subset.css');

  * {
    box-sizing: border-box;
  }

  html {
    overflow: hidden;
  }

  body {
    display: flex;
    position: relative;
    justify-content: center;

    font-family: Pretendard;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-smooth: never;
  }

  :root {
    display: flex;
    position: relative;
    flex-direction: column;
    background-color: black;
    width: 100%;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0); // 터치 음영 지우기
    user-select: none;
    background-color: white;
  }

  .mobile-wrapper {
    display: flex;
    position: relative;
    width: 100%;
    height: 100%;
    flex-direction: column;
    max-width: 460px;

    overscroll-behavior: none;
    height: 100vh;
    overflow-y: auto;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    ::-webkit-scrollbar {
      display: none;
    }
  }

  .toast-success {
    & > div {
      justify-content: left;
    }
  }

  .toast-error {
    & > div {
      justify-content: left;
    }
  }

  :root {
    /* Colors */
    --primary-color-100: #cafde1;
    --primary-color-200: #97fbcd;
    --primary-color-300: #62f5be;
    --primary-color-400: #3aebba;
    --primary-color-500: #00deb6; /* main-color */
    --primary-color-600: #00beae;
    --primary-color-700: #009e9f;
    --primary-color-800: #007380;
    --primary-color-900: #00556a;

    --accent-color-100: #fefbce;
    --accent-color-200: #fef69d;
    --accent-color-300: #fef06c;
    --accent-color-400: #fdea48;
    --accent-color-500: #fce00c;
    --accent-color-600: #d8bd08;
    --accent-color-700: #b59c06;
    --accent-color-800: #927c03;
    --accent-color-900: #786502;

    --neutral-color-100: #ffffff;
    --neutral-color-200: #dddddd;
    --neutral-color-300: #787878;
    --neutral-color-400: #464646;
    --neutral-color-500: #1a1a1a;
    --neutral-color-600: #121212;
    --neutral-color-700: #0d0d0d;
    --neutral-color-800: #000000; /* primary-black*/
  }

  /* html 기본 스타일 삭제 start */

  html,
  body,
  div,
  span,
  applet,
  object,
  iframe,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  blockquote,
  pre,
  a,
  abbr,
  acronym,
  address,
  big,
  cite,
  code,
  del,
  dfn,
  em,
  img,
  ins,
  kbd,
  q,
  s,
  samp,
  small,
  strike,
  strong,
  sub,
  sup,
  tt,
  var,
  b,
  u,
  i,
  center,
  dl,
  dt,
  dd,
  ol,
  ul,
  li,
  fieldset,
  form,
  label,
  legend,
  table,
  caption,
  tbody,
  tfoot,
  thead,
  tr,
  th,
  td,
  article,
  aside,
  canvas,
  details,
  embed,
  figure,
  figcaption,
  footer,
  header,
  hgroup,
  menu,
  nav,
  output,
  ruby,
  section,
  summary,
  time,
  mark,
  audio,
  video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    vertical-align: baseline;
  }
  /* HTML5 display-role reset for older browsers */
  article,
  aside,
  details,
  figcaption,
  figure,
  footer,
  header,
  hgroup,
  menu,
  nav,
  section {
    display: block;
  }

  ol,
  ul {
    list-style: none;
  }
  blockquote,
  q {
    quotes: none;
  }
  blockquote:before,
  blockquote:after,
  q:before,
  q:after {
    content: '';
    content: none;
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  /* html 기본 스타일 삭제 end */
`;
