import { css, keyframes } from '@emotion/css';

export const LoadingSpinner = () => {
  return (
    <div className={loadingSpinnerStyle}>
      <svg className={loadingSpinnerWrapStyle} viewBox='0 0 50 50'>
        <circle className={loadingSpinnerCircleStyle} cx='25' cy='25' r='20' fill='none' strokeWidth='4' />
      </svg>
    </div>
  );
};

const rotate = keyframes({
  '100%': {
    transform: 'rotate(360deg)',
  },
});
const dash = keyframes({
  '0%': {
    strokeDasharray: '1, 150',
    strokeDashoffset: '0',
  },
  '50%': {
    strokeDasharray: '90, 150',
    strokeDashoffset: '-35',
  },
  '100%': {
    strokeDasharray: '90, 150',
    strokeDashoffset: '-124',
  },
});

const loadingSpinnerStyle = css`
  display: flex;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
`;

const loadingSpinnerWrapStyle = css`
  animation: ${rotate} 2s linear infinite;
  width: 50px;
  height: 50px;
`;

const loadingSpinnerCircleStyle = css`
  stroke: #3e0085;
  stroke-linecap: round;
  animation: ${dash} 1.5s ease-in-out infinite;
`;
