import { css } from '@emotion/css';
import { ProjectLite } from '@src/models/project';
import { Img } from 'react-image';
import LocationIcon from '@src/assets/location-icon.svg';

interface BadgeProps {
  project: ProjectLite;
}

export const Badge = (props: BadgeProps) => {
  const { project } = props;

  const getPlayerCountLabel = () => {
    const { minPlayers, maxPlayers } = project;

    // minPlayers와 maxPlayers가 0으로 동일할 경우
    if (minPlayers === 0 && maxPlayers === 0) return null;

    // minPlayers와 maxPlayers가 동일하고 0이 아닌 경우
    if (minPlayers === maxPlayers) return `${minPlayers}인`;

    // minPlayers만 0이 아니고 maxPlayers가 0인 경우 (최소 인원만 제한)
    if (maxPlayers === 0) return `${minPlayers}이상`;

    // maxPlayers만 0이 아니고 minPlayers가 0인 경우 (최대 인원만 제한)
    if (minPlayers === 0) return `${maxPlayers}이하`;

    // minPlayers와 maxPlayers가 모두 0이 아니고, 다른 값을 가지는 경우
    return `${minPlayers}~${maxPlayers}인`;
  };

  const playerCountLabel = getPlayerCountLabel();

  const isFree = project.priceDisplay && project.isFree;

  return (
    <div className={BadgeStyle}>
      {project.playArea && (
        <div className={BadgePlayAreaItemStyle}>
          <Img src={LocationIcon} />
          <p>{props.project.playArea}</p>
        </div>
      )}

      {isFree && (
        <div className={BadgePriceItemStyle}>
          <p>무료</p>
        </div>
      )}

      {playerCountLabel && <div className={BadgePlayerCountItemStyle}>{playerCountLabel}</div>}

      {project.genres &&
        project.genres.map((genre, idx) => (
          <div key={idx} className={BadgeGenreItemStyle}>
            <p>{genre}</p>
          </div>
        ))}
    </div>
  );
};

const BadgeStyle = css`
  width: 100%;
  display: flex;
  gap: 2px;
  overflow-x: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  ::-webkit-scrollbar {
    display: none;
  }

  color: #555;

  /* Label1 */
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
`;

const BadgePlayAreaItemStyle = css`
  display: flex;
  flex-shrink: 0;
  height: 20px;
  padding: 0px 6px;
  align-items: center;
  gap: 2px;
  border-radius: 4px;
  background: #f2f9ff;
  color: #7ec2ff;
`;

const BadgePriceItemStyle = css`
  display: flex;
  flex-shrink: 0;
  height: 20px;
  padding: 0px 6px;
  align-items: center;
  gap: 2px;
  border-radius: 4px;
  background: #f4f0ff;
  color: #7800ff;
`;

const BadgePlayerCountItemStyle = css`
  display: flex;
  flex-shrink: 0;
  height: 20px;
  padding: 0px 6px;
  align-items: center;
  gap: 2px;
  border-radius: 4px;
  color: #555555;
  background: #f5f5f5;
`;

const BadgeGenreItemStyle = css`
  display: flex;
  flex-shrink: 0;
  height: 20px;
  padding: 0px 6px;
  align-items: center;
  gap: 2px;
  border-radius: 4px;
  color: #555555;
  background: #f5f5f5;
`;
