import { apiRequest } from '@src/apis/api-request';
import { Event } from '@src/models/event';
import { Story } from '@src/models/story';
import { UserInfo } from '@src/models/user-info';
import { QueryFunctionContext } from '@tanstack/react-query';

export const getUserInfo = async () => {
  return apiRequest<UserInfo>('get', '/api/me', '1.0');
};

export const getStories = async (queryObject: QueryFunctionContext) => {
  return apiRequest<Story[]>('get', '/api/stories', '1.0', queryObject);
};

export const getStory = async (id: string) => {
  return apiRequest<Story>('get', `/api/stories/${id}`, '1.1');
};

export const getEventPage = async (queryObject: QueryFunctionContext) => {
  return apiRequest<Event>('get', '/eventPage', '1.0', queryObject);
};

export const postProjectLike = async (projectId: string) => {
  return apiRequest('post', `/api/projects/${projectId}/like`, '1.1', {});
};
