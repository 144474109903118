import { PopularProject } from '@src/models/event';
import { ProjectLite } from '@src/models/project';
import { Story } from '@src/models/story';

interface DeeplinkParams {
  deeplink_path: string;
  utm_source: string;
  utm_campaign: string;
  utm_medium: string;
  utm_content: string;
  event_name: string;
  $desktop_url: string;
  $desktop_passive_deepview: string;
  $android_passive_deepview: string;
  $android_deepview: string;
  $android_url: string;
  $ios_deep_view: string;
  $ios_passive_deep_view: string;
  mt: string | number;
  flow: string | number;
}

export const createDeeplinkUrl = (project: ProjectLite | PopularProject, story?: Story): string => {
  const baseUrl = 'https://realworld.app.link/';
  const params: DeeplinkParams = {
    deeplink_path: `realworld://project?projectId=${project.id}`,
    utm_source: 'realworld_event_web',
    utm_campaign: `${story?.title ?? '온보딩 이벤트'} `,
    utm_medium: 'realworld_event_web',
    utm_content: `${project.name}`,
    event_name: 'app_link_share',
    $desktop_url: `https://realworld.to/projects/${project.id}`,
    $desktop_passive_deepview: 'realworld_deep_view',
    $android_passive_deepview: 'realworld_deep_view',
    $android_deepview: 'realworld_deep_view',
    $android_url: 'market://details?id=biz.uniquegood.realworld.sunguard',
    $ios_deep_view: 'realworld_deep_view',
    $ios_passive_deep_view: 'realworld_deep_view',
    mt: 8,
    flow: 0,
  };

  const queryString = Object.entries(params)
    .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
    .join('&');

  return `${baseUrl}?${queryString}`;
};
