import { css } from '@emotion/css';
import { ProjectLite } from '@src/models/project';
import ChurroIcon from '@src/assets/churro-icon.svg';

interface PriceProps {
  project: ProjectLite;
}

export const Price = ({ project }: PriceProps) => {
  if (!project) return null;

  const isDiscount = project.originalPrice > 0 && project.originalPrice > project.price;
  const discountPercent =
    isDiscount && Math.floor(((project.originalPrice - project.price) / project.originalPrice) * 100);
  const isPriceVisible = project.priceDisplay && !project.isFree;

  return (
    <div className={PriceStyle}>
      {isPriceVisible && (
        <>
          {isDiscount && <div className={PriceDiscountPercentStyle}>{discountPercent}%</div>}
          <div className={PriceCurrencyStyle}>
            {project.currency === 'Krw' && <p>{project.price.toLocaleString()}원</p>}
            {project.currency === 'Churro' && (
              <div className={PriceChurroTypeStyle}>
                <img src={ChurroIcon} alt='churro' />
                <p>{project.price.toLocaleString()}</p>
              </div>
            )}
            {isDiscount && <div className={PriceDiscountStyle}>{project.originalPrice.toLocaleString()}</div>}
          </div>
        </>
      )}
    </div>
  );
};

const PriceStyle = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2px;
`;

const PriceDiscountPercentStyle = css`
  color: var(--text-text-primary, #7800ff);
  text-align: center;

  /* Label1 */
  font-size: var(--font-size-font-size-6, 12px);
  font-style: normal;
  font-weight: var(--font-weight-font-weight-bold, 700);
  line-height: var(--line-height-font-height-6, 16px); /* 133.333% */
`;

const PriceCurrencyStyle = css`
  display: flex;
  gap: 4px;
  color: var(--text-text-default, #1a1a1a);
  text-align: center;

  /* Label1 */
  font-family: var(--font-family-Pretendard, Pretendard);
  font-size: var(--font-size-font-size-6, 12px);
  font-style: normal;
  font-weight: var(--font-weight-font-weight-bold, 700);
  line-height: var(--line-height-font-height-6, 16px); /* 133.333% */
`;

const PriceChurroTypeStyle = css`
  display: flex;
  align-items: center;
  > img {
    display: flex;
    width: 16px;
    height: 16px;
    padding: 1.435px 3.791px 1.333px 3.784px;
    justify-content: center;
    align-items: center;
  }
`;

const PriceDiscountStyle = css`
  display: flex;
  align-items: flex-end;
  color: var(--text-text-disabled, #bdbdbd);

  /* Caption2 */
  font-family: var(--font-family-Pretendard, Pretendard);
  font-size: var(--font-size-font-size-7, 10px);
  font-style: normal;
  font-weight: var(--font-weight-font-weight-medium, 500);
  line-height: var(--line-height-font-height-7, 14px); /* 140% */
  text-decoration-line: line-through;
`;
