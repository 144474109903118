export const initializeFlutterInterface = () => {
  window.sendMessageToFlutter = async (key: string, data: object): Promise<object> => {
    return await window.flutter_inappwebview.callHandler(key, data);
  };

  window.isFlutter = () => (window.flutter_inappwebview ? true : false);

  window.callbackForFlutter = (callbackName: FlutterCallbackType, args: unknown) => {
    console.log('callbackForFlutter', callbackName, args);
  };
};
