import { UserInfo } from '@src/models/user-info';

export const getUserInfoFromLocalStorage = (): UserInfo | null => {
  const userInfoString = localStorage.getItem('userInfo');
  if (!userInfoString) return null;
  try {
    const userInfo = JSON.parse(userInfoString) as UserInfo;
    return userInfo;
  } catch (error) {
    console.error('사용자 정보 파싱 중 오류 발생:', error);
    return null;
  }
};

export const formatNumber = (value: number) => {
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};
