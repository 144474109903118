import { css } from '@emotion/css';
import { Story } from '@src/models/story';
import { trackEvent } from '@src/utils/analytics';
import { useNavigate } from 'react-router-dom';

interface StoryItemProps {
  story: Story;
}

export const StoryItem = (props: StoryItemProps) => {
  const navigate = useNavigate();

  const handleClickStory = () => {
    trackEvent({
      eventName: 'click_story',
      properties: { storyTitle: props.story.title, storyId: props.story.id },
    });
    navigate(`${props.story.id}`);
  };

  return (
    <div className={StoryItemStyle} onClick={handleClickStory}>
      {props.story.title}
    </div>
  );
};

const StoryItemStyle = css`
  display: flex;
  width: 100%;
  height: 72px;
  align-items: center;
  padding: 12px 16px;
  color: black;
  border-radius: 8px;
  border: 1px solid var(--light-gray-200, #e8e9eb);
  background: var(--white, #fff);

  color: var(--black, #191919);
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 142.857% */
`;
