import { css } from '@emotion/css';
import { useGetEventPage } from '@src/apis/queries';
import { DagooCard } from '@src/components/DagooCard';
import { EventPopular } from '@src/components/EventPopular';
import { LoadingSpinner } from '@src/components/LoadingSpinner';
import { ProjectItem } from '@src/components/ProjectItem';
import { TopNavigation } from '@src/components/TopNavigationBar';
import { usePostMessage } from '@src/hooks/usePostMessage';
import { Event } from '@src/models/event';
import { trackEvent } from '@src/utils/analytics';
import { useEffect, useState } from 'react';

export const EventPage = () => {
  const { data: eventPageData, isLoading } = useGetEventPage();
  const [eventData, setEventData] = useState<Event>();
  const postMessageToParent = usePostMessage();
  const sendMessageToParent = () => {
    const closeMessage: PostMessageType<ActionMessage> = {
      type: 'action',
      data: {
        messageDataType: 'close',
        payload: { status: true },
      },
    };

    if (window.isFlutter()) postMessageToParent<ActionMessage>(closeMessage);
    else history.back();
  };

  useEffect(() => {
    if (eventPageData?.data) {
      setEventData(eventPageData.data as Event);
    }
  }, [eventPageData]);

  useEffect(() => {
    if (eventData) {
      trackEvent({
        eventName: 'view_event_page',
        properties: {
          playType: eventData.preference.name,
        },
      });
    }
  }, [eventData]);

  const renderLocalProjects = () => {
    if (eventData?.localProjects) {
      return eventData.localProjects.projects.map((project, idx) => (
        <ProjectItem
          key={idx}
          project={project}
          preferenceName={eventData.preference.name}
          section='local'
          isEvent
          isShowPrice
        />
      ));
    }
  };

  const renderRecommendProjects = () => {
    if (eventData?.recommendProjects) {
      return eventData.recommendProjects.projects.map((project, idx) => (
        <ProjectItem
          key={idx}
          project={project}
          preferenceName={eventData.preference.name}
          section='recommendation'
          isEvent
          isShowPrice
        />
      ));
    }
  };

  useEffect(() => {}, []);

  if (isLoading) return <LoadingSpinner />;

  return (
    <>
      <TopNavigation isClose={false} arrowColor={'white'} hexColor={'#3e0085'} onClickBack={sendMessageToParent} />
      <div className={EventPageStyle}>
        <div className={DagooCardSectionStyle}>
          {eventData?.preference && <DagooCard preference={eventData.preference} />}
        </div>
        <div className={EventRecommendationSectionStyle}>
          {eventData?.popularProjects && (
            <EventPopular
              userName={eventData.userName}
              preferenceSimilarity={eventData.preferenceSimilarity}
              popularProjects={eventData.popularProjects}
              preferenceName={eventData.preference.name}
            />
          )}
        </div>
        <div className={ProjectListSectionStyle}>
          {eventData?.localProjects && <p className={ProjectListSectionTitleStyle}>{eventData?.localProjects.title}</p>}
          {eventData?.localProjects && renderLocalProjects()}
        </div>
        <div className={ProjectListSectionStyle}>
          {eventData?.recommendProjects && (
            <p className={ProjectListSectionTitleStyle}>{eventData?.recommendProjects.title}</p>
          )}
          {eventData?.recommendProjects && renderRecommendProjects()}
        </div>
      </div>
    </>
  );
};

const EventPageStyle = css`
  width: 100%;
  height: 100%;
  padding-top: 48px;
  gap: 12px;
`;

const DagooCardSectionStyle = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #3e0085;
`;

const EventRecommendationSectionStyle = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 50px;
  background: #3e0085;
`;

const ProjectListSectionStyle = css`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 0 16px;
  padding-top: 48px;
`;

const ProjectListSectionTitleStyle = css`
  color: var(--text-text-default, #1a1a1a);
  white-space: pre-wrap;

  font-size: var(--font-size-font-size-3, 18px);
  font-style: normal;
  font-weight: var(--font-weight-font-weight-extrabold, 800);
  line-height: var(--line-height-font-height-3, 26px); /* 144.444% */
`;
