import { TopNavigation } from '@src/components/TopNavigationBar';

export const HomePage = () => {
  return (
    <div>
      <TopNavigation title='' isClose={true} />
      <button>Click me</button>
    </div>
  );
};
