import axios from 'axios';
import { Response } from '@src/models/response';
import { QueryFunctionContext } from '@tanstack/react-query';

const baseURL = import.meta.env.VITE_REALWORLD_CORE_API_URL;

const baseApi = axios.create({
  baseURL: baseURL,
  headers: { 'Content-Type': 'application/json' },
});

baseApi.interceptors.request.use(
  async (config) => {
    const accessToken = localStorage.getItem('accessToken');
    if (accessToken) config.headers.Authorization = `Bearer ${accessToken}`;

    return config;
  },
  (error) => Promise.reject(error),
);

async function apiRequest<T extends object>(
  method: HttpMethodType,
  url: string,
  apiVersion: string,
  queryObject?: QueryFunctionContext,
): Promise<Response<T> | Response<T[]>>;
async function apiRequest<T extends object, R extends object>(
  method: HttpMethodType,
  url: string,
  apiVersion: string,
  body?: T,
): Promise<Response<R> | Response<R[]>>;
async function apiRequest<T extends object, R extends object>(
  method: HttpMethodType,
  url: string,
  apiVersion: string,
  queryObjectOrBody?: QueryFunctionContext | T,
): Promise<Response<T> | Response<T[]> | Response<R> | Response<R[]>> {
  const config = { headers: { 'x-rwd-api-version': apiVersion } };
  switch (method) {
    case 'get': {
      const params =
        queryObjectOrBody && 'queryKey' in queryObjectOrBody && queryObjectOrBody.queryKey.length > 1
          ? queryObjectOrBody.queryKey[1]
          : {};
      return baseApi
        .get<Response<T> | Response<T[]>>(url, { ...config, params: params })
        .then((res) => res.data as Response<T> | Response<T[]>);
    }
    case 'post': {
      return baseApi
        .post<Response<R> | Response<R[]>>(url, JSON.stringify(queryObjectOrBody!), { ...config })
        .then((res) => res.data as Response<R> | Response<R[]>);
    }

    default:
      throw new Error(`Unsupported method: ${method}`);
  }
}

export { apiRequest };
