import { css, keyframes } from '@emotion/css';
import { Preference } from '@src/models/event';
import DagooCardFront from '@src/assets/dagoo-card-front.svg';

interface DagooCardProps {
  preference: Preference;
}

export const DagooCard = ({ preference }: DagooCardProps) => {
  return (
    <div className={DagooCardStyle}>
      <p className={DagooCardSectionTitleStyle}>내 플레이 유형은?</p>
      <div className={`${DagooCardItemStyle}`}>
        <div className={`${DagooCardImageStyle} ${floatingAndFadeInAnimation}`}>
          <img src={preference.imageUrl} alt='Dagoo' />
          <div className={DagooCardTextWrapStyle}>
            <p className={DagooCardHeaderStyle}>{preference.name}</p>
            <div style={{ whiteSpace: 'pre-wrap' }}>
              <p className={DagooCardDescStyle}> {preference.description.replace(/\\n/g, '\n')}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const DagooCardStyle = css`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
`;

const DagooCardSectionTitleStyle = css`
  width: 170px;
  font-size: 24px;
  line-height: 36px;
  font-weight: 800;
  color: #fff;
  text-align: center;
  display: inline-block;
  padding-bottom: 16px;
`;

const DagooCardItemStyle = css`
  display: flex;
  width: 296px;
  height: 365px;
  padding-top: 21px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
`;

const DagooCardImageStyle = css`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 24px;
  background: url(${DagooCardFront});
  > img {
    min-height: 141px;
  }
`;

const DagooCardTextWrapStyle = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 7px;
  align-self: stretch;
  padding-top: 7px;
`;

const DagooCardHeaderStyle = css`
  color: var(--text-text-default, #1a1a1a);
  text-align: center;

  /* Headline */
  font-size: var(--font-size-font-size-2, 20px);
  font-style: normal;
  font-weight: var(--font-weight-font-weight-extrabold, 800);
  line-height: var(--line-height-font-height-2, 28px); /* 140% */
`;

const DagooCardDescStyle = css`
  color: var(--text-text-description, #555);
  text-align: center;
  white-space: pre-wrap;
  padding: 0 30px;

  /* Caption1 */
  font-size: var(--font-size-font-size-6, 12px);
  font-style: normal;
  font-weight: var(--font-weight-font-weight-medium, 500);
  line-height: var(--line-height-font-height-6, 16px); /* 133.333% */
`;

const float = keyframes`
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(0px);
  }
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const floatingAndFadeInAnimation = css`
  animation:
    ${fadeIn} 800ms ease-in forwards,
    ${float} 3s ease-in-out infinite;
`;

// const flip = keyframes`
//   0% { transform: rotateY(0deg); }
//   100% { transform: rotateY(3600deg); }
// `;

// const flipAnimation = css`
//   animation: ${flip} 5s cubic-bezier(0.1, 0.5, 0.1, 1) forwards;
//   transform-style: preserve-3d;
//   animation-timing-function: ease-out;
// `;
